<script lang="ts" setup>
import type { SelectField, TextField } from '~/types/storyblok/shared';
import { emailRegex } from 'data/patterns/email'

const props = defineProps<{
    fields: (TextField | SelectField)[]
    legal: string
    label: string
}>()

type FocusableComponent = {
    focusField: () => void
}

const emit = defineEmits(['success', 'error'])
const storefrontStore = useStorefrontStore()
const gtm = useGTM()
const inputRefs = ref<{[key:string] : FocusableComponent | null}>({});

onMounted(() => {
    setTimeout(() => {
        inputRefs.value[props.fields[0].id]?.focusField()
    }, 100)
})

interface Form {
    loading: boolean
    fields: {
        [key: string]: {
            value: string
            isError: boolean
        }
    }
}

const formState = reactive<Form>({
    loading: false,
    fields: props.fields.reduce((acc, field) => {
        acc[field.id] = {
            value: '',
            isError: false
        }
        return acc
    }, {} as Form['fields'])
})

// There are several validating functions in this codebase. They should be combined and refactored or replaced with a library in the future.

function validate() {
    let isValid = true
    let isFieldFocused = false
    for (const field of props.fields) {
        if (field.component === 'text-field' && field.is_email_input_type) {
            if (!emailRegex.test(formState.fields[field.id].value)) {
                formState.fields[field.id].isError = true
                isValid = false
                
                if (!isFieldFocused) {
                    inputRefs.value[field.id]?.focusField()
                    isFieldFocused = true
                }
                
            }
            else {
                formState.fields[field.id].isError = false
            }
        }
        else if (!formState.fields[field.id].value) {
            formState.fields[field.id].isError = true
            isValid = false

            if (!isFieldFocused) {
                    inputRefs.value[field.id]?.focusField()
                    isFieldFocused = true
                }
        }
        else {
            formState.fields[field.id].isError = false
        }
    }
    return isValid
}
async function subscribe() {
    if (validate()) {
        formState.loading = true
        await sendForm()
        formState.loading = false
    }
}

async function sendForm() {
    const body: Record<string, string> = {
        store: storefrontStore.currentStorefrontCode,
        countryCode: storefrontStore.currentMarketCountryCode,
    }
    for (const field of props.fields) {
        body[field.id] = formState.fields[field.id].value
    }
    const { error } = await useFetch('/api/newsletter', {
        method: 'POST',
        body,
    })

    if (!error.value) {
        emit('success')
    }
    else {
        emit('error')
    }

    gtm.pushNewsletterEvent(!error.value ? 'success' : 'fail', formState.fields.email.value)
}

</script>

<template>
    <form v-if="fields" class="form">
        <template v-for="field, index in fields" :key="field.id">
            <FormTextField v-if="field.component === 'text-field'" :field="field" :state="formState.fields[field.id]" 
                :ref="(el) => (inputRefs[field.id] = el as unknown as FocusableComponent)"/>
            <FormSelectField v-if="field.component === 'select-field'" :field="field"
                :state="formState.fields[field.id]" :ref="(el) => (inputRefs[field.id] = el as unknown as FocusableComponent)" />
        </template>
        <div v-if="legal" class="legal" v-html="legal" />
        <ButtonBasic v-if="label" class="subscribe" role="button" design="no-border" size="medium" :uppercase="false"
            background-color="var(--black)" color="var(--white)" hoverColor="var(--dark-hover)" @click="subscribe"
            data-test="segmented-popup-subscribe">
            {{ label }}
        </ButtonBasic>
    </form>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
}
</style>
